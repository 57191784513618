import { Input, Layout, Menu, Typography, Form, theme, Button, Space } from "antd";
import {
  RedoOutlined,
  HeartOutlined,
  PoundCircleOutlined,
  HistoryOutlined,
  UserOutlined,
  HomeOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";
import React from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";

const accountItems = [
  {
    label: "Профиль",
    key: "/account",
    icon: <UserOutlined />,
  },
  {
    label: "Адреса",
    key: "/account/addresses",
    icon: <HomeOutlined />,
  },
  {
    label: "Безопасность",
    key: "/account/password",
    icon: <SecurityScanOutlined />,
  },
  {
    label: "История заказов",
    key: "/account/history",
    icon: <HistoryOutlined />,
  },
  {
    label: "Возвраты",
    key: "/account/return",
    icon: <RedoOutlined />,
  },
  {
    label: "Бонусные баллы",
    key: "/account/reward",
    icon: <PoundCircleOutlined />,
  },
  {
    label: "Избранные",
    key: "/account/wishlist",
    icon: <HeartOutlined />,
  },
];

const Password = () => {
  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  const navigate  = useNavigate();

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  return (
    <div className="region">
      <Layout.Content style={{ padding: "0 48px" }}>
        <Breadcrumb />
        <Layout
          style={{
            padding: "24px 0",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
          }}
        >
          <Layout.Sider style={{ background: colorBgContainer }} width={200}>
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%" }}
              items={accountItems}
              onClick={handleMenuClick}
            />
          </Layout.Sider>
          <Layout.Content style={{ padding: "0 24px", maxWidth: 500, minHeight: 280 }}>
            <Typography.Title level={2}>Изменить пароль</Typography.Title>
            <Form.Item name="" label="Пароль">
              <Input.Password />
            </Form.Item>
            <br />
            <Form.Item>
              <Space>
                <Button type="primary" htmlType="submit">
                  Сохранить
                </Button>
              </Space>
            </Form.Item>
          </Layout.Content>
        </Layout>
      </Layout.Content>
    </div>
  );
};

export default Password;
