import { Alert, Button, Layout, List, Menu, Skeleton, Typography, theme } from "antd";
import {
  RedoOutlined,
  HeartOutlined,
  PoundCircleOutlined,
  HistoryOutlined,
  UserOutlined,
  HomeOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";

import Breadcrumb from "components/Breadcrumb";
import useSmartNavigate from "hooks/useSmartNavigate";
import { useDispatch, useSelector } from "react-redux";
import { Fragment, useEffect } from "react";
import { fetchHistories } from "store/slices/customerSlice";
import { loadingStatus } from "helpers/fetcher";
import { getImage } from "helpers";

const accountItems = [
  {
    label: "Профиль",
    key: "/account",
    icon: <UserOutlined />,
  },
  {
    label: "Адреса",
    key: "/account/addresses",
    icon: <HomeOutlined />,
  },
  {
    label: "Безопасность",
    key: "/account/password",
    icon: <SecurityScanOutlined />,
  },
  {
    label: "История заказов",
    key: "/account/history",
    icon: <HistoryOutlined />,
  },
  {
    label: "Возвраты",
    key: "/account/return",
    icon: <RedoOutlined />,
  },
  {
    label: "Бонусные баллы",
    key: "/account/reward",
    icon: <PoundCircleOutlined />,
  },
  {
    label: "Избранные",
    key: "/account/wishlist",
    icon: <HeartOutlined />,
  },
];

const Password = () => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.customer.histories);
  const { navigate } = useSmartNavigate(); 

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  useEffect(() => {
    dispatch(fetchHistories());
  }, [dispatch]);

  return (
    <div className='region'>
      <Layout.Content style={{ padding: "0 48px" }}>
        <Breadcrumb />
        <Layout
          style={{
            padding: "24px 0",
            background: "#fff",
            borderRadius: "#fff",
          }}
        >
          <Layout.Sider style={{ background: "#fff" }} width={200}>
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%" }}
              items={accountItems}
              onClick={handleMenuClick}
            />
          </Layout.Sider>
          <Layout.Content style={{ padding: "0 24px", minHeight: 280 }}>
            <Typography.Title level={2}>История заказов</Typography.Title>
            {status === loadingStatus.SUCCEEDED ? (
              <List
                itemLayout="horizontal"
                dataSource={data.orders}
                renderItem={(item, index) => (
                  <Fragment key={item.order_id}>
                    <List.Item>
                      <List.Item.Meta
                        title={item.name}
                        description={item.date_added}
                      />
                      <div>
                        <Typography.Title level={4}>{item.total}</Typography.Title>
                        <p><Alert message={item.status} type="info" /></p>
                      </div>
                    </List.Item>
                    <List.Item>
                      <div>
                        {item?.products.map(p => (
                          <img
                            key={p.product_id}
                            src={getImage(p.image)}
                            alt={p.name}
                            style={{ border: "2px solid #eee", verticalAlign: "bottom", maxWidth: 70, maxHeight: 70 }}
                          />
                        ))}
                      </div>
                      <div><Button type="link" icon={<RedoOutlined />}>Повторить заказ</Button></div>
                    </List.Item>
                  </Fragment>
                )}
              />
            ) : (
              <Skeleton active paragraph={{ rows: 5 }} />
            )}
          </Layout.Content>
        </Layout>
      </Layout.Content>
    </div>
  );
};

export default Password;
