import { BrowserRouter } from 'react-router-dom';
import { Layout } from 'antd';

import HeaderLayout from "./HeaderLayout";
import FooterLayout from "./FooterLayout";

const { Content } = Layout;

const MainLayout = ({ children }) => (
  <Layout className="rn-layout">
    <BrowserRouter>
      <HeaderLayout />

      <Content>
        {children}
      </Content>

      <FooterLayout />
    </BrowserRouter>
  </Layout>
);

export default MainLayout;
