import React from "react";
import { Avatar, List, Rate, Space } from "antd";
import { LikeOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons';

const Reviews = ({ review }) => {
  console.log(review)
  return (
    <List
      itemLayout="vertical"
      size="large"
      // pagination={{
      //   onChange: setPage,
      //   pageSize: data.limit,
      //   total: data.total,
      //   current: page,
      // }}
      dataSource={review.data.reviews}
      renderItem={(item, index) => (
        <List.Item key={index}>
          <List.Item.Meta
            title={<>{item.author} <Rate allowHalf value={item.rating} /></>}
            description={item.date_added}
          />
          {item.text}
        </List.Item>
      )}
    />
  );
};

export default Reviews;
