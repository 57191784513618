import { Input, Layout, Menu, Typography, Form, theme, Button, Skeleton } from "antd";
import {
  RedoOutlined,
  HeartOutlined,
  PoundCircleOutlined,
  HistoryOutlined,
  UserOutlined,
  HomeOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import Breadcrumb from "components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import useSmartNavigate from "hooks/useSmartNavigate";
import { postProfile, fetchProfile } from "store/slices/customerSlice";
import { loadingStatus } from "helpers/fetcher";

const accountItems = [
  {
    label: "Профиль",
    key: "/account",
    icon: <UserOutlined />,
  },
  {
    label: "Адреса",
    key: "/account/addresses",
    icon: <HomeOutlined />,
  },
  {
    label: "Безопасность",
    key: "/account/password",
    icon: <SecurityScanOutlined />,
  },
  {
    label: "История заказов",
    key: "/account/history",
    icon: <HistoryOutlined />,
  },
  {
    label: "Возвраты",
    key: "/account/return",
    icon: <RedoOutlined />,
  },
  {
    label: "Бонусные баллы",
    key: "/account/reward",
    icon: <PoundCircleOutlined />,
  },
  {
    label: "Избранные",
    key: "/account/wishlist",
    icon: <HeartOutlined />,
  },
];

const Profile = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.customer.profile);
  const { navigate } = useSmartNavigate(); 

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  const handleFinish = async (values) => {
    setLoading(true);
    const res = await postProfile(values);
    if (res.error) {
      alert('Error');
      // {
      //   "error_warning": "Такой E-Mail уже зарегистрирован!",
      //   "error_firstname": "",
      //   "error_lastname": "",
      //   "error_email": "",
      //   "error_telephone": "",
      //   "error_custom_field": [],
      //   "error": 1
      // }
    } else {
      // {
      //   "success": "Ваша учетная запись была успешно обновлена!"
      // }
    }
    setLoading(false);
    console.log(res)
  };

  useEffect(() => {
    dispatch(fetchProfile());
  }, [dispatch]);

  return (
    <div className="region">
      <Breadcrumb />
      <Layout
        style={{
          padding: "24px 0",
          background: "#fff",
        }}
      >
        <Layout.Sider style={{ background: "#fff" }} width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%" }}
            items={accountItems}
            onClick={handleMenuClick}
          />
        </Layout.Sider>
        <Layout.Content style={{ padding: "0 24px", maxWidth: 600 }}>
          {status === loadingStatus.SUCCEEDED ? (
            <Form
              name="profile"
              initialValues={data}
              labelCol={{ span: 6 }}
              onFinish={handleFinish}
              // autoComplete="off"
            >
              <Typography.Title level={2}>Информация об аккаунте</Typography.Title>
              <Form.Item name="firstname" label="Имя" rules={[{ required: true, message: "Обязательное поле" }]}>
              <Input />
              </Form.Item>
              <Form.Item name="lastname" label="Фамилия" rules={[{ required: true, message: "Обязательное поле" }]}>
              <Input />
              </Form.Item>
              <Form.Item name="telephone" label="Номер телефона" rules={[{ required: true, message: "Обязательное поле" }]}>
                <Input />
              </Form.Item>
              <Form.Item name="email" label="E-mail" rules={[{ required: true, message: "Обязательное поле" }]}>
              <Input />
              </Form.Item>
              <Form.Item>
                <Button type="primary" htmlType="submit" loading={loading}>
                  Сохранить изменения
                </Button>
              </Form.Item>
            </Form>
          ) : (
            <Skeleton active paragraph={{ rows: 6 }} />
          )}
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Profile;
