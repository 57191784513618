const loadingStatus = {
  IDLE: "idle",
  LOADING: "loading",
  SUCCEEDED: "succeeded",
  FAILED: "failed",
};

const getHeaders = () => {
  const customer = JSON.parse(localStorage.getItem("customer") || "{}");
  return customer.token ? { Authorization: `${customer.token}` } : {};
};

const SERVER_URL = process.env.NODE_ENV === "production" ?
  process.env.REACT_APP_SERVER_URL :
  process.env.REACT_APP_DEV_URL;

const fetcher = async (endpoint, options = {}) => {
  try {
    const response = await fetch(`${SERVER_URL}${endpoint}`, {
      headers: getHeaders(),
      ...options,
    });
    return response;
  } catch (e) {
    // TODO: logger
    alert(`Fatal error from fetcher: ${e.toString()}`);
  }
  return false;
};

export default fetcher;

export {
  SERVER_URL,
  getHeaders,
  loadingStatus,
};
