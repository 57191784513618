import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Skeleton, Typography } from 'antd';

import Breadcrumb from 'components/Breadcrumb';
import { fetchPage } from 'store/slices/pageSlice';
import { loadingStatus } from 'helpers/fetcher';


const PageDefault = () => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.page.page);
  const { "*": path } = useParams();

  useEffect(() => {
    dispatch(fetchPage(`/${path}/`));
  }, [dispatch, path]);

  return (
    <div className='region'>

      {status === loadingStatus.SUCCEEDED ? (
        <>
          <Breadcrumb />      
          <Typography.Title>{data.heading_title}</Typography.Title>
          <div dangerouslySetInnerHTML={{ __html: data.description }} />
        </>
      ) : (
        <>
          <Skeleton.Button active block />
          <br /><br />
          <Skeleton.Button active block />
          <br /><br /><br />
          <Skeleton active paragraph={{ rows: 5 }} />
        </>
      )}
    </div>
  );
};

export default PageDefault;
