import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";

const addToCart = async (product_id, quantity) => {
  const formData = new FormData();
  formData.append("product_id", product_id);
  formData.append("quantity", quantity);
  const response = await fetcher("/index.php?route=checkout/cart/add", {
    method: "POST",
    body: formData,
  });
  return response.json();
};

const fetchCartProducts = createAsyncThunk("cart.PRODUCTS", async () => {
  const response = await fetcher("/index.php?route=checkout/cart");
  return response.json();
});


const initialState = {
  products: {
    data: {},
    status: loadingStatus.IDLE,
  },
};

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchCartProducts.pending, (state, action) => {
        state.products.status = loadingStatus.LOADING;
      })
      .addCase(fetchCartProducts.fulfilled, (state, action) => {
        state.products.status = loadingStatus.SUCCEEDED;
        state.products.data = action.payload;
      })
  }
});

export default cartSlice.reducer;

// export const { postAdded, postUpdated, reactionAdded } = playerSlice.actions;
export {
  addToCart,
  fetchCartProducts,
};
