import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, theme, Button, Flex, Image, Card, Divider, Rate, Modal, Space, Input, Tabs, Descriptions, Skeleton } from "antd";
import {
  HeartOutlined,
  ShoppingCartOutlined,
  StarOutlined,
} from "@ant-design/icons";

import Breadcrumb from "components/Breadcrumb";
import DeliveryCalculate from "components/modal/DeliveryCalculate";
import Reviews from "./Reviews";
import useSmartNavigate from "hooks/useSmartNavigate";
import { fetchProduct, fetchReview } from "store/slices/productSlice";
import { loadingStatus } from "helpers/fetcher";

const Product = () => {
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const navigate  = useNavigate();
  const { hrefNavigate } = useSmartNavigate(); 
  const { pathname, search } = useLocation();
  const { product, review }= useSelector((state) => state.product);
  const { data, status } = product;

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();


  const handleAddressSubmit = () => {
    setAddressModalIsOpen(false);
  };

  const handleAddressCancel = () => {
    setAddressModalIsOpen(false);
  };

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  useEffect(() => {
    dispatch(fetchProduct(`${pathname}${search}`));
  }, [dispatch, pathname, search]);

  useEffect(() => {
    if (data.product_id) dispatch(fetchReview(data.product_id));
  }, [dispatch, data.product_id]);

  return (
    <>
      <div className="region">
        <Breadcrumb />

        <Flex justify="space-between">
          <div style={{ width: '50%' }}>
            {status === loadingStatus.SUCCEEDED ? (
              <Image width={500} src={data.popup} />
            ) : (
              <Skeleton.Image active style={{ width: 500, height: 500 }} />
            )}
            <br/>
            {data.images && data.images.map((d) => (<Image width={150} src={d.thumb} />))}
          </div>
          <div style={{ width: '50%' }}>
            {status === loadingStatus.SUCCEEDED ? (
              <Card title={<Typography.Title level={3}>{data.name}</Typography.Title>}>
                <Flex align="center">
                  <p>Артикул: 23423432</p>
                  <Typography.Title level={3}>{data.reviews}</Typography.Title>
                  <div><StarOutlined /> {data.rating}</div>
                </Flex>
                <Divider />

                <Flex justify="space-around" align="center">
                  <div>
                    <Typography.Title level={3}>345,90 Р / кг</Typography.Title>
                    <p>макс вес ?(для весовых),</p>
                    <p>стоимость за кг?(для весовых),</p>
                    <p>вес?(для штучных),</p>
                  </div>
                  <div>
                    <Button type="primary" shape="round" icon={<ShoppingCartOutlined />} size="large">
                      В корзину
                    </Button>
                    <HeartOutlined style={{ color: 'red' }} />
                    <p>Доставка по городу 200р</p>
                    <p>Доставка по Москве и области <a onClick={() => setAddressModalIsOpen(true)}>Рассчитать</a></p>
                  </div>
                </Flex>
              </Card>
            ) : (
              <Skeleton active paragraph={{ rows: 8 }} />
            )}
          </div>
        </Flex>

        {status === loadingStatus.SUCCEEDED ? (
          <Tabs
            defaultActiveKey="1"
            onChange={console.log}
            items={[
              { key: "1", label: "О товаре", children: <div dangerouslySetInnerHTML={{ __html: data.onec_description }} /> },
              {
                key: "2",
                label: <>Отзывы покупателей <Rate allowHalf value={data.rating} style={{ fontSize: 15 }} /> {data.rating}</>,
                children: <Reviews review={review} />,
              },
            ]}
          />
        ) : (
          <Skeleton active paragraph={{ rows: 4 }} />
        )}

        

      </div>
      <Modal
        width={800}
        open={addressModalIsOpen}
        onCancel={handleAddressCancel}
        footer={null}
      >
        <DeliveryCalculate />
      </Modal>
    </>
  );
};

export default Product;
