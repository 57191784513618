import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Dropdown, Button } from "antd";
import { DownOutlined } from "@ant-design/icons";

import { fetchCategoriesList } from "store/slices/menuSlice";
import useSmartNavigate from "hooks/useSmartNavigate";
import { menuFlatter } from "helpers";
import { loadingStatus } from "helpers/fetcher";

const Megamenu = () => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.menu.categoriesList);

  const { hrefNavigate } = useSmartNavigate(); 

  const handleMenuClick = ({ key }) => {
    const flat = menuFlatter(data.categories);
    const item = flat.find((f) => f.key === key);
    hrefNavigate(item.href);
  };

  useEffect(() => {
    dispatch(fetchCategoriesList());
  }, [dispatch]);

  return (
    <Dropdown menu={{ items: data.categories, onClick: handleMenuClick }} trigger={["click"]}>
      <Button
        type="primary"
        size="large"
        loading={status !== loadingStatus.SUCCEEDED}
      >
        Каталог <DownOutlined />
      </Button>
    </Dropdown>
  );
};

export default Megamenu;
