import { Alert, Avatar, Badge, Button, Card, Flex, Form, Input, InputNumber, Layout, List, Modal, Steps, Typography, theme } from "antd";
import { LeftOutlined, DeleteOutlined } from "@ant-design/icons";

import Breadcrumb from "components/Breadcrumb";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import DeliveryCalculate from "components/modal/DeliveryCalculate";

const data = [
  {
    title: 'Колбаса Кавказская в в.у., 500 гр',
  },
  {
    title: 'Колбаса Кавказская в в.у., 800 гр',
  },
  {
    title: 'Колбаса Кавказская в в.у., 100 гр',
  },
];

const Checkout = () => {
  const { token: { colorBgContainer, borderRadiusLG } } = theme.useToken();

  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const [showCheckoutForm, setShowCheckoutForm] = useState(false);

  const navigate  = useNavigate();

  const handleAddressSubmit = () => {
    setAddressModalIsOpen(false);
  };

  const handleSubmitCode = () => {
    setShowCheckoutForm(true);
  };

  return (
    <div className="region">
      <Breadcrumb />
      <br /><br />
      <Steps
        type="navigation"
        current={1}
        items={[
          {
            title: 'Корзина',
          },
          {
            title: 'Оформление',
          },
          {
            title: 'Спасибо',
          },
        ]}
      />

      <Typography.Title level={3}>Оформление</Typography.Title>
      <br/>

      <Card>
        <Typography.Title level={2}>Войти или зарегистрироваться</Typography.Title>
        <br/>
        <Flex>
          <Form>
            <Form.Item
              label="Телефон"
              name="username"
              rules={[
                {
                  required: true,
                  message: 'Please input your username!',
                },
              ]}
            >
              <Input />
            </Form.Item>
            <Alert message="Мы отправим Вам код для входа в СМС." type="info" showIcon />
          </Form>
          <div style={{ paddingLeft: 50 }}>
            <Button type="primary" size="large" onClick={handleSubmitCode}>Получить код</Button>
          </div>
        </Flex>
      </Card>
      <br/>

      <Layout
        style={{
          display: showCheckoutForm ? "flex" : "none",
          padding: "24px 0",
          background: colorBgContainer,
          borderRadius: borderRadiusLG,
        }}
      >
        <Layout.Content style={{ padding: "0 24px" }}>
          <Card title="Контактные данные">
            Ф.И.О. _________ Телефон ___________  E-Mail ____________
          </Card>
          <br/>
          <Card title="Адрес доставки">
            ______________
          </Card>
          <br/>
          <Card title="Время доставки">
            ______________
          </Card>
          <br/>
          <Card title="Способ оплаты">
            ______________
          </Card>
        </Layout.Content>
        <Layout.Sider style={{ background: colorBgContainer }} width={320}>
          <Card>
            <Flex justify="space-between" align="flex-end" className="mb-20">
              <strong>Итог:</strong>
              <Typography.Title level={3}>509.00 ₽</Typography.Title>
            </Flex>
            <Flex justify="space-between" align="flex-end" className="mb-10">
              <div>Вы получите бонусов:</div>
              <Typography.Title level={4}>+ 12</Typography.Title>
            </Flex>
            <Flex justify="space-between" align="flex-end" className="mb-10">
              <div>Стоимость товаров:</div>
              <div>360.00 ₽</div>
            </Flex>
            <Flex justify="space-between" align="flex-end" className="mb-10">
              <div>Стоимость доставки:</div>
              <div>149.00 ₽</div>
            </Flex>
            <Flex justify="space-between" align="flex-end" className="mb-10">
              <div>Вес заказа:</div>
              <div>1.3 кг</div>
            </Flex>
          </Card>
          <Button type="primary" block size="large" onClick={() => navigate("/payment-success")}>Оплатить заказ</Button>
        </Layout.Sider>
      </Layout>

    </div>
  );
};

export default Checkout;
