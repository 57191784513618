import { Button, Result } from "antd";

const PaymentSuccess = () => {
  return (
    <div className="region">
      <Result
        status="success"
        title="Ваш заказ успешно оформлен!"
        subTitle={
          <>
            <p>Номер вашего заказа: 2017182</p>
            <p>В течение 1-5 минут вам придет смс с деталями заказа</p>
            <p>Если у Вас возникли вопросы по заказу, обращайтесь по номеру <a>+7 928 333 32 23</a></p>
          </>
        }
        extra={<Button type="primary" size="large">Продолжить покупки</Button>}
      />
    </div>
  );
};

export default PaymentSuccess;
