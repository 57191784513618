import { Button, Card, Carousel, Flex, Image, List, Typography, theme } from 'antd';

import ProductItem from 'pages/catalog/category/ProductItem';
import { productsListData, advantagesData } from '../../constants/dummy';

const contentStyle = {
  height: '160px',
  color: '#fff',
  lineHeight: '160px',
  textAlign: 'center',
  background: '#364d79',
};

const Home = () => {
  const { token } = theme.useToken();
  console.log(token)

  return (
    <div className='region'>
      <Carousel autoplay>
        <div>
          <h3 style={contentStyle}>1</h3>
        </div>
        <div>
          <h3 style={contentStyle}>2</h3>
        </div>
        <div>
          <h3 style={contentStyle}>3</h3>
        </div>
        <div>
          <h3 style={contentStyle}>4</h3>
        </div>
      </Carousel>
      <br/>
      <Flex gap="middle">
        <Image
          width={100}
          preview={{
            imageRender: () => (
              <div>
                <img
                  alt=""
                  src="https://rayanhalal.ru/image/cache/catalog/100_141-500x375.png"
                />
                <p><Button>Акционный товар</Button></p>
              </div>
            ),
            toolbarRender: () => null,
          }}
          src="https://rayanhalal.ru/image/cache/catalog/100_141-500x375.png"
        />
        <Image
          width={100}
          preview={{
            imageRender: () => (
              <div>
                <img
                  alt=""
                  src="https://rayanhalal.ru/image/cache/catalog/kolbasakavaz1-500x375.jpg"
                />
                <p><Button>Заказать в один клик</Button></p>
              </div>
            ),
            toolbarRender: () => null,
          }}
          src="https://rayanhalal.ru/image/cache/catalog/kolbasakavaz1-500x375.jpg"
        />
      </Flex>
      <br/>
      <List
        className="advantages-list"
        grid={{ gutter: 16, column: 5 }}
        dataSource={advantagesData}
        renderItem={(item) => (
          <List.Item>
            <Card>
              <img src={item.img} alt="" /> {item.title}
            </Card>
          </List.Item>
        )}
      />

      <Typography.Title level={3}>Хит продаж</Typography.Title>
      <List
        grid={{
          gutter: 16,
          column: 4,
        }}
        dataSource={productsListData}
        renderItem={(item) => (
          <ProductItem item={item} />
        )}
      />

      <div>
        Content content<br/>
      </div>
    </div>
  );
};

export default Home;
