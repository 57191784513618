import { Input, Layout, Menu, Typography, Form, theme, Button, Table, Skeleton } from "antd";
import {
  RedoOutlined,
  HeartOutlined,
  PoundCircleOutlined,
  HistoryOutlined,
  UserOutlined,
  HomeOutlined,
  SecurityScanOutlined,
  EditOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Breadcrumb from "components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import useSmartNavigate from "hooks/useSmartNavigate";
import { fetchAddress } from "store/slices/customerSlice";
import { loadingStatus } from "helpers/fetcher";

const accountItems = [
  {
    label: "Профиль",
    key: "/account",
    icon: <UserOutlined />,
  },
  {
    label: "Адреса",
    key: "/account/addresses",
    icon: <HomeOutlined />,
  },
  {
    label: "Безопасность",
    key: "/account/password",
    icon: <SecurityScanOutlined />,
  },
  {
    label: "История заказов",
    key: "/account/history",
    icon: <HistoryOutlined />,
  },
  {
    label: "Возвраты",
    key: "/account/return",
    icon: <RedoOutlined />,
  },
  {
    label: "Бонусные баллы",
    key: "/account/reward",
    icon: <PoundCircleOutlined />,
  },
  {
    label: "Избранные",
    key: "/account/wishlist",
    icon: <HeartOutlined />,
  },
];

const Addresses = () => {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.customer.address);
  const { navigate } = useSmartNavigate(); 

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  useEffect(() => {
    dispatch(fetchAddress());
  }, [dispatch]);

  // TODO: https://ant.design/components/table
  // Editable Cells
  return (
    <div className="region">
      <Breadcrumb />
      <Layout
        style={{ padding: "24px 0", background: "#fff" }}
      >
        <Layout.Sider style={{ background: "#fff" }} width={200}>
          <Menu
            mode="inline"
            defaultSelectedKeys={["1"]}
            defaultOpenKeys={["sub1"]}
            style={{ height: "100%" }}
            items={accountItems}
            onClick={handleMenuClick}
          />
        </Layout.Sider>
        <Layout.Content style={{ padding: "0 24px", minHeight: 280 }}>
          <Typography.Title level={2}>Адреса</Typography.Title>
          {status === loadingStatus.SUCCEEDED ? (
            <Table
              columns={[
                { title: 'Адрес', dataIndex: 'address', key: 'address_id' },
                { title: '', dataIndex: '', key: 'x', render: () => <><DeleteOutlined style={{ fontSize: "1.4em", color: "red" }} /> <EditOutlined style={{ fontSize: "1.4em" }} /></> },
              ]}
              dataSource={data.addresses?.map((a) => ({
                key: a.address_id,
                address: <div dangerouslySetInnerHTML={{ __html: a.address }} />,
              }))}
            />
          ) : (
            <Skeleton active paragraph={{ rows: 5 }} />
          )}
        </Layout.Content>
      </Layout>
    </div>
  );
};

export default Addresses;
