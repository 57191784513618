import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Checkbox,
  Collapse,
  Divider,
  Flex,
  Form,
  Layout,
  List,
  Menu,
  Pagination,
  Select,
  Skeleton,
  Typography,
} from "antd";

import Breadcrumb from "components/Breadcrumb";
import ProductItem from "./ProductItem";
import { fetchCategory } from "store/slices/productSlice";
import useSmartNavigate from "hooks/useSmartNavigate";
import { menuFlatter } from "helpers";
import { loadingStatus } from "helpers/fetcher";

const { Sider, Content } = Layout;

const contentStyle = {
  minHeight: 120,
  lineHeight: '120px',
  color: '#fff',
  paddingLeft: 20,
  backgroundColor: 'transparent',
};
const siderStyle = {
  lineHeight: '120px',
  color: '#fff',
  backgroundColor: 'transparent',
};
const layoutStyle = {
  borderRadius: 8,
  overflow: 'hidden',
  minHeight: '100vh',
  margin: '0 auto',
};

const Category = () => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.product.category);
  const categoriesList = useSelector((state) => state.menu.categoriesList);
  const { pathname, search } = useLocation();
  // const { "*": path } = useParams();
  const { navigate, hrefNavigate } = useSmartNavigate();

  const handleMenuClick = ({ key }) => {
    const flat = menuFlatter(categoriesList.data.categories);
    const item = flat.find((f) => f.key === key);
    hrefNavigate(item.href);
  };

  const handleSortSelect = (_, obj) => {
    hrefNavigate(obj.href);
  };

  const handlePagination = (page) => {
    const url = new URL(window.location.href.replace(/&amp;/g, "&"));
    const params = new URLSearchParams(url.search);
    params.set("page", page);
    navigate(`${url.pathname}?${params.toString()}`)    
  };

  useEffect(() => {
    dispatch(fetchCategory(`${pathname}${search}`));
  }, [dispatch, pathname, search]);

  return (
    <div className="region">
      <Layout style={layoutStyle}>
        <Sider width={250} style={siderStyle}>
          {categoriesList.status === loadingStatus.SUCCEEDED ? (
            <Menu
              onClick={handleMenuClick}
              defaultSelectedKeys={["20"]}
              defaultOpenKeys={["2"]}
              mode="inline"
              items={categoriesList.data.categories}
            />
          ) : (
            <Skeleton active paragraph={{ rows: 10 }} />
          )}
        </Sider>
        <Content style={contentStyle}>
          {status === loadingStatus.SUCCEEDED ? (
            <>
              <Breadcrumb />
              <Typography.Title level={2}>{data.heading_title}</Typography.Title>
              <Flex gap="small">
                {data.categories.map((c, index) => (
                  <Button
                    onClick={() => hrefNavigate(c.href)}
                    key={index}
                    type="dashed"
                  >
                    {c.name}
                  </Button>
                ))}
              </Flex>
              {data.sorts?.length > 0 && (
                <Select
                  onChange={handleSortSelect}
                  defaultValue={`${data.sort}-${data.order}`}
                  options={data.sorts}
                />
              )}
            </>
          ) : (
            <Skeleton active />
          )}
          <Divider />
          <List
            grid={{
              gutter: 16,
              column: 4,
            }}
            dataSource={data.products || Array(10).fill({})}
            renderItem={(item) => (
              <Skeleton active avatar loading={status !== loadingStatus.SUCCEEDED}>
                <ProductItem item={item} />
              </Skeleton>
            )}
          />
          <Pagination
            defaultCurrent={1}
            pageSize={data.limit}
            total={data.product_total}
            onChange={handlePagination}
            showTotal={() => data.results}
          />
        </Content>
      </Layout>
    </div>
  );
};

export default Category;
