import React from "react";
import {
  Routes,
  Route,
  Outlet,
  Navigate,
} from "react-router-dom";

import useCustomer from "hooks/useCustomer";
import pages from "./constants/routes";

import Home from "./pages/home";

import PageDefault from "./pages/page";
import PageNewsList from "pages/page/page-news-list";
import PageNewsDetail from "pages/page/page-news-detail";
import PageContact from "pages/page/page-contact";

import Catalog from "./pages/catalog";
import Category from "./pages/catalog/category";
import Product from "./pages/catalog/product";

import Cart from "./pages/cart";
import Checkout from "./pages/checkout";
import PaymentSuccess from "./pages/common/payment-success";

import Profile from "./pages/account/profile";
import Addresses from "./pages/account/addresses";
import Password from "./pages/account/password";
import History from "./pages/account/history";
import Wishlist from "./pages/account/wishlist";

import Error404 from "./pages/common/404";

const PublicRoutes = () => <Outlet />;

const PrivateRoutes = () => {
  const { accessToken } = useCustomer();

  return (
    accessToken ? (
      <Outlet />
    ) : (
      <Navigate to={pages.LOGIN} />
    )
  );
};

const Router = () => {
  return (
    <Routes>
      <Route exact path="/" element={<PublicRoutes />}>
        <Route exact path="/" element={<Home />} />
        <Route exact path={pages.CATALOG} element={<Catalog />} />
        <Route exact path="/catalog/*" element={<Category />} />
        <Route exact path="/product/*" element={<Product />} />

        <Route exact path="/cart" element={<Cart />} />
        <Route exact path="/checkout" element={<Checkout />} />
        <Route exact path="/payment-success" element={<PaymentSuccess />} />
        <Route exact path="/news" element={<PageNewsList />} />
        <Route exact path="/news/*" element={<PageNewsDetail />} />
        <Route exact path="/contact" element={<PageContact />} />
      </Route>

      <Route exact path="/page" element={<PublicRoutes />}>
        <Route exact path="/page/*" element={<PageDefault />} />
      </Route>

      <Route exact path="/account" element={<PrivateRoutes />}>
        <Route exact path="/account" element={<Profile />} />
        {/* <Route exact path="/account/profile" element={<Profile />} /> */}
        <Route exact path="/account/addresses/*" element={<Addresses />} />
        <Route exact path="/account/password" element={<Password />} />
        <Route exact path="/account/history" element={<History />} />

        <Route exact path="/account/wishlist" element={<Wishlist />} />
      </Route>

      <Route path="*" element={<Error404 />} />
    </Routes>
  );
};

export default Router;
