import { Alert, Avatar, Badge, Button, Card, Flex, InputNumber, Layout, List, Modal, Skeleton, Steps, Typography, theme } from "antd";
import { LeftOutlined, DeleteOutlined } from "@ant-design/icons";

import Breadcrumb from "components/Breadcrumb";
import { useEffect, useState } from "react";
import DeliveryCalculate from "components/modal/DeliveryCalculate";
import { useDispatch, useSelector } from "react-redux";
import useSmartNavigate from "hooks/useSmartNavigate";
import { fetchCartProducts } from "store/slices/cartSlice";
import { loadingStatus } from "helpers/fetcher";
import { getImage } from "helpers";

const Cart = () => {
  const [addressModalIsOpen, setAddressModalIsOpen] = useState(false);
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.cart.products);
  const { navigate } = useSmartNavigate(); 

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  const handleAddressSubmit = () => {
    setAddressModalIsOpen(false);
  };

  const handleAddressCancel = () => {
    setAddressModalIsOpen(false);
  };

  useEffect(() => {
    dispatch(fetchCartProducts());
  }, [dispatch]);

  return (
    <>
      <div className="region">
        <Breadcrumb />
        <Button icon={<LeftOutlined />}>Прордолжить покупки</Button>
        <br /><br />
        <Steps
          type="navigation"
          current={0}
          items={[
            { title: 'Корзина' },
            { title: 'Оформление' },
            { title: 'Спасибо' },
          ]}
        />
        <Typography.Title level={3}>Моя корзина</Typography.Title>

        {status === loadingStatus.SUCCEEDED ? (
          <Layout
            style={{
              padding: "24px 0",
              background: "#fff",
              borderRadius: "#fff",
            }}
          >
            <Layout.Content style={{ padding: "0 24px" }}>
              <List
                itemLayout="horizontal"
                dataSource={data.products}
                renderItem={(item, index) => (
                  <List.Item actions={[<DeleteOutlined style={{ color: "red" }} />]}>
                    <List.Item.Meta
                      style={{ alignItems: "center" }}
                      avatar={
                        <img
                          alt={item.name}
                          src={item.thumb}
                          style={{ width: 120, height: "auto" }}
                        />
                      }
                      title={
                        <Flex justify="space-between" key={item.product_id}>
                          <div>
                            {item.name}
                          </div>
                          <div>
                            <Typography.Title level={4}>{item.price} ₽</Typography.Title>
                            <div style={{ fontWeight: 'normal' }}>за {item.weight}</div>
                          </div>
                          <div>
                            <Typography.Title level={4}>{item.total} ₽</Typography.Title>
                            <InputNumber
                              addonBefore="-"
                              addonAfter="+"
                              defaultValue={item.quantity}
                              size="large"
                              style={{ width: 100 }}
                            />
                          </div>
                        </Flex>
                      }
                      // description="Артикул 22349"
                    />
                  </List.Item>
                )}
              />
              
            </Layout.Content>
            <Layout.Sider style={{ background: "#fff" }} width={320}>
              <Card>
                <Flex justify="space-between" align="flex-end" className="mb-20">
                  <strong>Итог:</strong>
                  <Typography.Title level={3}>509.00 ₽</Typography.Title>
                </Flex>
                <Flex justify="space-between" align="flex-end" className="mb-10">
                  <div>Вы получите бонусов:</div>
                  <Typography.Title level={4}>+ 12</Typography.Title>
                </Flex>
                <Flex justify="space-between" align="flex-end" className="mb-10">
                  <div>Стоимость товаров:</div>
                  <div>360.00 ₽</div>
                </Flex>
                <Flex justify="space-between" align="flex-end" className="mb-10">
                  <div>Стоимость доставки:</div>
                  <div>149.00 ₽</div>
                </Flex>
                <Flex justify="space-between" align="flex-end" className="mb-10">
                  <div>Вес заказа:</div>
                  <div>1.3 кг</div>
                </Flex>
                <Alert message={<p>Доставка по Москве и области <a onClick={() => setAddressModalIsOpen(true)}>Рассчитать</a></p>} type="success" />
              </Card>
              <Button type="primary" block size="large" onClick={() => navigate("/checkout")}>Оформить заказ</Button>
            </Layout.Sider>
          </Layout>
        ) : (
          <Skeleton active paragraph={{ rows: 5 }} />
        )}

      </div>
      <Modal
        width={800}
        open={addressModalIsOpen}
        onCancel={handleAddressCancel}
        footer={null}
      >
        <DeliveryCalculate />
      </Modal>
    </>
  );
};

export default Cart;
