import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { List, Skeleton, Space, Typography } from 'antd';
import { ClockCircleOutlined, EyeOutlined } from '@ant-design/icons';

import Breadcrumb from 'components/Breadcrumb';
import { fetchNewsList } from 'store/slices/pageSlice';
import { loadingStatus } from 'helpers/fetcher';


const PageNewsList = () => {
  const [page, setPage] = useState();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.page.newsList);
  const navigate  = useNavigate();

  const handleNewsClick = (e, href) => {
    e.preventDefault();
    const url = new URL(href.replace(/&amp;/g, "&"));
    navigate(`/news/${url.searchParams.get('news_id')}`);
  };

  useEffect(() => {
    dispatch(fetchNewsList(page ? `?page=${page}` : ""));
  }, [dispatch, page]);

  return (
    <div className='region'>

      {status === loadingStatus.SUCCEEDED ? (
        <>
          <Breadcrumb />      
          <Typography.Title>{data.heading_title}</Typography.Title>

          <List
            itemLayout="vertical"
            size="large"
            pagination={{
              onChange: setPage,
              pageSize: data.limit,
              total: data.total,
              current: page,
            }}
            dataSource={data.news_list}
            footer={<div>{data.results}</div>}
            renderItem={(item) => (
              <List.Item
                key={item.title}
                actions={[
                  <Space><ClockCircleOutlined /> {item.posted}</Space>,
                  <Space><EyeOutlined /> {item.viewed}</Space>,
                ]}
                extra={item.thumb && (
                  <a href="#" onClick={(e) => handleNewsClick(e, item.href)}>
                    <img width={272} alt="logo" src={item.thumb} />
                  </a>
                )}
              >
                <List.Item.Meta
                  title={<a href="#" onClick={(e) => handleNewsClick(e, item.href)}>{item.title}</a>}
                  description={item.description}
                />
                {item.content}
              </List.Item>
            )}
          />
        </>
      ) : (
        <>
          <Skeleton.Button active block />
          <br /><br />
          <Skeleton.Button active block />
          <br /><br /><br />
          <Skeleton active paragraph={{ rows: 5 }} />
        </>
      )}
    </div>
  );
};

export default PageNewsList;
