import { useState } from "react";
import { Alert, Button, Divider, Form, Input, Modal, Space, Typography } from "antd";
import { ArrowLeftOutlined } from '@ant-design/icons';
import InputMask from "react-input-mask";

import { postAuthCodeSend, postAuthCodeValidate } from "store/slices/customerSlice";
import useSmartNavigate from "hooks/useSmartNavigate";
import useCustomer from "hooks/useCustomer";

const SignIn = ({ open, onClose }) => {
  const [status, setStatus] = useState({ type: "", text: "" });
  const [telephone, setTelephone] = useState("");
  const [code, setCode] = useState();

  const { navigate } = useSmartNavigate(); 
  const { setCustomer } = useCustomer(); 

  const handleTelephone = (e) => {
    const clean = e.target.value.replace(/\(|\)|-| /g, () => "");
    setTelephone(clean.split("_")[0]);
  };

  const handleSubmitTelephone = async () => {
    setStatus({ type: "info", text: "На Ваш номер придет смс с кодом" });
    const res = await postAuthCodeSend(telephone);
    setCode(res);
    setStatus();
  };
  
  const handleCode = async (value) => {
    if (value.length === 4) {
      setStatus({ type: "info", text: "Проверка кода..." });
      console.log(value);
      const res = await postAuthCodeValidate(telephone, value);
      if (res.status === "success") {
        setStatus();
        // разблокировать и войти
        setStatus({ type: "info", text: res.text });
        setCustomer(res.customer_info);
        navigate("/account");
      } else {
        setStatus({ type: "error", text: res.text });
        console.log("Код введен неверно")
      }
    }
  };

  const handleClearCode = () => {
    setCode();
  };

  return (
    <Modal
      width={420}
      centered
      open={open}
      onCancel={onClose}
      footer={null}
    >
      <div style={{ height: 455 }}>
        {code ? (
          <CodeForm
            handleCode={handleCode}
            handleClearCode={handleClearCode}
            code={code}
            status={status}
          />
        ) : (
          <TelephoneForm
            handleSubmitTelephone={handleSubmitTelephone}
            handleTelephone={handleTelephone}
            telephone={telephone}
            status={status}
          />
        )}
      </div>
    </Modal>
  );
};

const CodeForm = ({ handleCode, handleClearCode, code, status }) => (
  <Form
    name="signin-code"
    layout="vertical"
  >
    <Typography.Title level={2}>
      <Space>
        <Button shape="circle" icon={<ArrowLeftOutlined />} onClick={handleClearCode} />
        Введите код
      </Space>
      <Typography.Title level={5} className="text-gray mt-10">
        Мы отправили код на номер<br />
        {code.telephone}
      </Typography.Title>
    </Typography.Title>
    <Divider />
    <Form.Item
      name="code"
      label="Код из смс"
      rules={[{ required: true, message: "Введите код" }]}
    >
      <Input.OTP length={4} size="large" onChange={handleCode} disabled={!!status?.type} />
    </Form.Item>
    <p><a href="#">Отправить код повторно через 2 минуты</a></p>
    {status?.type && <Alert message={status.text} type={status.type} banner />}
  </Form>
);

const TelephoneForm = ({ handleSubmitTelephone, handleTelephone, telephone, status }) => (
  <Form
    name="signin-telephone"
    layout="vertical"
    onFinish={handleSubmitTelephone}
  >
    <Typography.Title level={2}>
      Войти или зарегистрироваться
      <Typography.Title level={5} className="text-gray mt-10">
        Чтобы сохранять корзину, историю покупок, добавлять в избранное, участвовать в акциях и программах
      </Typography.Title>
    </Typography.Title>
    <Divider />
    <Form.Item name="telephone" label="Ваш телефон">
      <InputMask mask="+7 (999) 999-99-99" onChange={handleTelephone} disabled={!!status?.type}>
        <Input />
      </InputMask>
    </Form.Item>
    <Form.Item>
      <Button
        type="primary"
        htmlType="submit"
        block
        size="large"
        disabled={telephone.length < 12}
        loading={!!status?.type}
      >
        Получить код
      </Button>
    </Form.Item>
    <p>Продолжая Вы соглашаетесь с политикой конфиденциальности</p>
    {status?.type && <Alert message={status.text} type={status.type} banner />}
  </Form>
);

export default SignIn;
