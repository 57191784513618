import { useNavigate } from 'react-router-dom';
import { Card, List, Skeleton, Typography } from 'antd';

import Breadcrumb from 'components/Breadcrumb';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { fetchCategory } from 'store/slices/productSlice';
import { getImage } from 'helpers';
import { loadingStatus } from 'helpers/fetcher';
import useSmartNavigate from 'hooks/useSmartNavigate';

const Catalog = () => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.product.category);
  const { hrefNavigate } = useSmartNavigate(); 

  useEffect(() => {
    dispatch(fetchCategory("/catalog/"));
  }, [dispatch]);

  return (
    <div className='region'>
      <Breadcrumb />
      <Typography.Title level={3}>Каталог</Typography.Title>

      <List
        grid={{
          gutter: 16,
          column: 5,
        }}
        dataSource={data.categories || Array(10).fill({})}
        renderItem={(item) => (
          <Skeleton active avatar loading={status !== loadingStatus.SUCCEEDED}>
            <List.Item>
              <Card onClick={() => hrefNavigate(item.href)}>
                <img src={getImage(item.image)} alt="" className="img-responsive" />
                <strong>{item.name}</strong>
              </Card>
            </List.Item>
          </Skeleton>
        )}
      />
    </div>
  );
};

export default Catalog;
