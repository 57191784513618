import { useDispatch } from 'react-redux';
import { Button, List, Rate, Tag } from 'antd';
import { PlusOutlined, HeartOutlined } from '@ant-design/icons';

import { addToCart, fetchCartProducts } from 'store/slices/cartSlice';
import useSmartNavigate from 'hooks/useSmartNavigate';

const ProductItem = ({ item }) => {
  const { hrefNavigate } = useSmartNavigate(); 
  const dispatch = useDispatch();

  const handleAddToCart = async (product_id, quantity) => {
    const info = await addToCart(product_id, quantity);
    console.log(info)
    dispatch(fetchCartProducts());
  };

  return (
    <List.Item>
      <div className="rn-product-card">
        <div className="rn-product-card__wishlist">
          <HeartOutlined style={{ fontSize: 26, color: 'red' }} />
        </div>
        <img src={item.thumb} alt="" className="rn-product-card__img img-responsive" onClick={() => hrefNavigate(item.href)} />
        <div className="rn-product-card__meta">
          <Rate allowHalf value={item.rating} /> {item.rating}
          <div className="rn-product-card__title" onClick={() => hrefNavigate(item.href)}>{item.name}</div>
          <div className="rn-product-card__bottom">
            <div className="rn-product-card__bottom_l">
              <div className="rn-product-card__price">{item.price} р</div>
              <Tag>за {item.weight}</Tag>
              {/* {item.price_kg && (
                <p style={{ color: 'red', fontSize: 19 }}>{item.price_kg} руб</p>
              )} */}
            </div>
            <div>
              <Button
                onClick={() => handleAddToCart(item.product_id, 1)}
                type="primary"
                size="large"
                icon={<PlusOutlined style={{ fontSize: '26px' }} />}
              />
            </div>
          </div>
        </div>
      </div>
    </List.Item>
  );
};

export default ProductItem;
