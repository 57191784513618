import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import fetcher, { loadingStatus } from "helpers/fetcher";

const fetchPage = createAsyncThunk("page.PAGE", async (q = "") => {
  const response = await fetcher(q);
  return response.json();
});

const fetchNewsList = createAsyncThunk("page.NEWS.LIST", async (q = "") => {
  const response = await fetcher(`/news/${q}`);
  return response.json();
});

const fetchNewsDetail = createAsyncThunk("page.NEWS.DETAIL", async (q = "") => {
  const response = await fetcher(`/news/${q}`);
  return response.json();
});

const initialState = {
  page: {
    data: {},
    status: loadingStatus.IDLE,
  },
  newsList: {
    data: {},
    status: loadingStatus.IDLE,
  },
  newsDetail: {
    data: {},
    status: loadingStatus.IDLE,
  },
};

const pageSlice = createSlice({
  name: "page",
  initialState,
  reducers: {},
  extraReducers(builder) {
    builder
      .addCase(fetchPage.pending, (state, action) => {
        state.page.status = loadingStatus.LOADING;
      })
      .addCase(fetchPage.fulfilled, (state, action) => {
        state.page.status = loadingStatus.SUCCEEDED;
        state.page.data = action.payload;
      })
      .addCase(fetchNewsList.pending, (state, action) => {
        state.newsList.status = loadingStatus.LOADING;
      })
      .addCase(fetchNewsList.fulfilled, (state, action) => {
        state.newsList.status = loadingStatus.SUCCEEDED;
        state.newsList.data = action.payload;
      })
      .addCase(fetchNewsDetail.pending, (state, action) => {
        state.newsDetail.status = loadingStatus.LOADING;
      })
      .addCase(fetchNewsDetail.fulfilled, (state, action) => {
        state.newsDetail.status = loadingStatus.SUCCEEDED;
        state.newsDetail.data = action.payload;
      })
  }
});

export default pageSlice.reducer;

// export const { postAdded, postUpdated, reactionAdded } = playerSlice.actions;
export {
  fetchPage,
  fetchNewsList,
  fetchNewsDetail,
};
