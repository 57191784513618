import { useNavigate } from 'react-router-dom';
import { Input, Dropdown, Flex, Layout, Menu, Space } from 'antd';
import { DownOutlined, ShoppingCartOutlined, HeartOutlined, UserOutlined } from '@ant-design/icons';

import Megamenu from 'components/menu/Megamenu';
import { useEffect, useState } from 'react';
import SignIn from 'components/modal/SignIn';
import { useDispatch, useSelector } from 'react-redux';
import { fetchCartProducts } from 'store/slices/cartSlice';

const { Header } = Layout;
const { Search } = Input;

const cityItems = [
  { label: 'Москва и область', key: 'msk' },
  { label: 'Нальчик', key: 'nal' },
];

const pageItems = [
  {
    label: 'Каталог',
    key: 'catalog/',
  },
  {
    label: 'Компания',
    key: 'page/company',
    children: [
      { label: 'О компании', key: 'page/o-nas' },
      { label: 'Вакансии', key: 'page/vakansii' },
      { label: 'Оставьте отзыв', key: 'page/feedback' },
      { label: 'Наши сертификаты', key: 'page/sertifikaty' },
      { label: 'Новости', key: 'news' },
    ]
  },
  {
    label: 'Доставка',
    key: 'page/dostavka-nalchik',
  },
  {
    label: 'Оплата',
    key: 'page/oplata-i-dostavka',
  },
  {
    label: 'Контакты',
    key: '/contact',
  },
  {
    label: 'Кабинет',
    key: 'account',
  },
];

const HeaderLayout = () => {
  const [signinShow, setSigninShow] = useState(false);
  const navigate  = useNavigate();
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.cart.products);

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  useEffect(() => {
    dispatch(fetchCartProducts());
  }, [dispatch]);

  console.log(data, status);

  return (
    <>
      <Flex vertical={false} justify="space-between" align="center" style={{ padding: "0 30px" }}>
        <Dropdown menu={{ items: cityItems }} trigger={['click']}>
          <a onClick={(e) => e.preventDefault()}>
            <Space>
              Нальчик
              <DownOutlined />
            </Space>
          </a>
        </Dropdown>

        <Menu
          mode="horizontal"
          items={pageItems}
          onClick={handleMenuClick}
          style={{ flex: 1, minWidth: 0 }}
        />
        <div>
          +7 918 727-78-80
        </div>
      </Flex>
      <Header className="header region">
        <img src='https://rayanhalal.ru/image/catalog/logo_new-new-mirror.jpg' alt='' style={{ width: 200 }} />
        <div style={{ width: 40 }} />
        <Megamenu />
        <div style={{ width: 40 }} />
        <Search
          placeholder="input search text"
          onSearch={(a, b, c) => console.log(a, b, c)}
          style={{ width: 700 }}
        />
        <div style={{ marginLeft: 50 }}>
          <UserOutlined onClick={() => setSigninShow(true)} style={{ fontSize: 25, color: "green", marginLeft: 20 }} />
          <HeartOutlined style={{ fontSize: 25, color: "red", marginLeft: 20 }}  />
          <ShoppingCartOutlined style={{ fontSize: 25, color: "green", marginLeft: 20 }}  onClick={() => navigate('/cart')} />
        </div>
      </Header>
      <br/>
      {signinShow && (
        <SignIn open={signinShow} onClose={() => setSigninShow(false)} />
      )}
    </>
  );
};

export default HeaderLayout;
