import { useNavigate } from "react-router-dom";

const useSmartNavigate = () => {
  const navigate = useNavigate();

  const hrefNavigate = (href) => {
    const url = new URL(href.replace(/&amp;/g, "&"));
    navigate(url.pathname);
  };

  return {
    navigate,
    hrefNavigate,
  };
};

export default useSmartNavigate;
