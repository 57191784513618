import { Alert, Input, Space } from "antd";
import { useState } from "react";

const DeliveryCalculate = () => {
  const [result, setResult] = useState();

  return (
    <Space direction="vertical">
      <h3>Рассчитать стоимость доставки</h3>

      <Alert message="Стоимость доставки зависит от загруженности дорог и может изменяться с течением времени." type="info" showIcon />

      <Input.Search
        placeholder="Введите полный адрес"
        onSearch={(value, _e, info) => setResult(true)}
      />

      {result && <Alert message="Стоимость доставки: 340 Р" type="success" />}

      <img src="https://acfwp.ru/wp-content/uploads/2018/03/pole-googlemap-v-adminkejpg.jpg" alt="" className="img-responsive" />

      
    </Space>
  );
};

export default DeliveryCalculate;
