const menu = {
  CATEGORIES_LIST: "/index.php?route=common/megamenu",
};

const product = {
  // CATEGORY: "/catalog/", // index.php?route=product/category&path=1_3_82
  PRODUCT: "/index.php?route=product/product", // product_id=133
};

const customer = {
  "/index.php?route=account/account": "/my-account/",
  "/index.php?route=account/edit": "/edit-account/",
  "/index.php?route=account/address": "/address-book/",
  "/index.php?route=account/order": "/order-history/",
  "/index.php?route=account/order/info&order_id=": "/index.php?route=account/order/info&order_id=",
  "/index.php?route=account/wishlist": "/wishlist/",
};

export {
  menu,
  product,
  customer,
};
