import { Button, Card, Layout, List, Menu, Skeleton, Typography, theme } from "antd";
import {
  RedoOutlined,
  HeartOutlined,
  PoundCircleOutlined,
  HistoryOutlined,
  UserOutlined,
  HomeOutlined,
  SecurityScanOutlined,
} from "@ant-design/icons";

import Breadcrumb from "components/Breadcrumb";
import { useDispatch, useSelector } from "react-redux";
import useSmartNavigate from "hooks/useSmartNavigate";
import { useEffect } from "react";
import { loadingStatus } from "helpers/fetcher";
import { fetchWishlist } from "store/slices/customerSlice";

const accountItems = [
  {
    label: "Профиль",
    key: "/account",
    icon: <UserOutlined />,
  },
  {
    label: "Адреса",
    key: "/account/addresses",
    icon: <HomeOutlined />,
  },
  {
    label: "Безопасность",
    key: "/account/password",
    icon: <SecurityScanOutlined />,
  },
  {
    label: "История заказов",
    key: "/account/history",
    icon: <HistoryOutlined />,
  },
  {
    label: "Возвраты",
    key: "/account/return",
    icon: <RedoOutlined />,
  },
  {
    label: "Бонусные баллы",
    key: "/account/reward",
    icon: <PoundCircleOutlined />,
  },
  {
    label: "Избранные",
    key: "/account/wishlist",
    icon: <HeartOutlined />,
  },
];

const Wishlist = () => {
  const dispatch = useDispatch();
  const { data, status } = useSelector((state) => state.customer.wishlist);
  const { navigate } = useSmartNavigate(); 

  const handleMenuClick = ({ item, key, keyPath, domEvent }) => {
    navigate(key);
  };

  useEffect(() => {
    dispatch(fetchWishlist());
  }, [dispatch]);

  return (
    <div className='region'>
      <Layout.Content style={{ padding: "0 48px" }}>
        <Breadcrumb />
        <Layout
          style={{
            padding: "24px 0",
            background: "#fff",
            borderRadius: "#fff",
          }}
        >
          <Layout.Sider style={{ background: "#fff" }} width={200}>
            <Menu
              mode="inline"
              defaultSelectedKeys={["1"]}
              defaultOpenKeys={["sub1"]}
              style={{ height: "100%" }}
              items={accountItems}
              onClick={handleMenuClick}
            />
          </Layout.Sider>
          <Layout.Content style={{ padding: "0 24px", minHeight: 280 }}>
            <Typography.Title level={2}>Избранное</Typography.Title>
            {status === loadingStatus.SUCCEEDED ? (
              <List
                dataSource={data?.products}
                renderItem={(item) => (
                  <List.Item key={item.product_id}>
                    <Card
                      hoverable
                      cover={<img alt="example" src={item.img} />}
                      actions={[
                        <strong style={{ fontSize: 16 }}>{item.price} руб</strong>,
                        <Button>В корзину</Button>,
                      ]}
                    >
                      <Card.Meta title={item.title} description="www.instagram.com" />
                    </Card>
                  </List.Item>
                )}
              />
            ) : (
              <Skeleton active paragraph={{ rows: 5 }} />
            )}
          </Layout.Content>
        </Layout>
      </Layout.Content>
    </div>
  );
};

export default Wishlist;
