import { Card, Layout, List } from 'antd';
import { advantagesData } from '../../constants/dummy';

const { Footer } = Layout;

const FooterLayout = () => {
  return (
    <Footer>
      <div className='region'>
        <List
          className="advantages-list"
          grid={{ gutter: 16, column: 5 }}
          dataSource={advantagesData}
          renderItem={(item) => (
            <List.Item>
              <Card>
                <img src={item.img} alt="" /> {item.title}
              </Card>
            </List.Item>
          )}
        />

        Ant Design ©{new Date().getFullYear()} Created by Ant UED
      </div>
    </Footer>
  );
};

export default FooterLayout;
