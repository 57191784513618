import { useState } from "react";

const useCustomer = () => {
  const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem("customer") || "{}")
);

  const saveCustomer = (customer) => {
    setCustomer(customer);
    localStorage.setItem("customer", JSON.stringify(customer));
  };

  const removeCustomer = () => {
    setCustomer();
    localStorage.removeItem("customer");
  };

  return {
    customer,
    accessToken: customer?.token,
    setCustomer: saveCustomer,
    removeCustomer,
  }
};

export default useCustomer;
