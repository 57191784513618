const productsListData = [
  {
    img: 'http://rayanhalal.ru/image/cache/catalog/1-250x188.jpg',
    title: 'Балык мякоть говяжья, 1 кг',
    url: '/catalog/product',
    price: 500.00,
    meta: 'Цена за 1 кг',
  },
  {
    img: 'http://rayanhalal.ru/image/cache/catalog/1-250x188.jpg',
    title: 'Вырезка говяжья, 1.50 кг',
    url: '/catalog/product',
    price: 250.30,
    meta: 'Цена за 1,5 кг',
  },
  {
    img: 'http://rayanhalal.ru/image/cache/catalog/1-250x188.jpg',
    title: 'Мякоть (тазобедренная часть), 1 кг',
    url: '/catalog/product',
    price: 430.00,
    meta: 'Цена за 800 гр',
  },
  {
    img: 'http://rayanhalal.ru/image/cache/catalog/1-250x188.jpg',
    title: 'Ребра говяжьи, 1 кг',
    url: '/catalog/product',
    price: 430.80,
    meta: 'Цена за 1 кг',
  },
  {
    img: 'http://rayanhalal.ru/image/cache/catalog/1-250x188.jpg',
    title: 'Мякоть Трубка, 2.00 кг',
    url: '/catalog/product',
    price: 200.00,
    meta: 'Цена за 1 кг',
  },
];

const advantagesData = [
  {
    img: 'https://rayanhalal.ru/site/catalog/view/theme/default/image/icon-halal.png',
    title: 'БЕЗ СВИНИНЫ И ЕЕ КОМПОНЕНТОВ',
  },
  {
    img: 'https://rayanhalal.ru/site/catalog/view/theme/default/image/icon-cow.png',
    title: 'ОХЛАЖДЕННОЕ МЯСО СОБСТВЕННОГО ЗАБОЯ',
  },
  {
    img: 'https://rayanhalal.ru/site/catalog/view/theme/default/image/icon-nature.png',
    title: 'БЕЗ КОНСЕРВАНТОВ И КРАСИТЕЛЕЙ',
  },
  {
    img: 'https://rayanhalal.ru/site/catalog/view/theme/default/image/icon-dna.png',
    title: 'БЕЗ ГМО И ЗАМЕНИТЕЛЕЙ МЯСА',
  },
  {
    img: 'https://rayanhalal.ru/site/catalog/view/theme/default/image/icon-eggs.png',
    title: 'НАТУРАЛЬНЫЕ МОЛОКО И ЯИЦО',
  },
];

export {
  productsListData,
  advantagesData,
};
